// @js-import "@shared/vendor/jquery-1.9.1.min.js"
// @js-import "@shared/jquery-fn.js"
// @js-import "@shared/utils.js"
// @js-import "@shared/components.js"
// @js-import "@shared/components-utils.js"
// @js-import "@shared/screen-size.js"
// @js-import "@shared/CmsMenuUtils.js"
// @js-import "@shared/platform.js"

const trapFocusModal = (element) => {
    const focusableElements = element.querySelectorAll('a, button');
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    element.addEventListener('keydown', e => {
        if (e.key === 'Tab') {
            if (e.shiftKey) {
                // Przesunięcie wstecz
                if (document.activeElement === firstElement) {
                    e.preventDefault();
                    lastElement.focus();
                }
            } else {
                // Przesunięcie do przodu
                if (document.activeElement === lastElement) {
                    e.preventDefault();
                    firstElement.focus();
                }
            }
        }
    });
};


ComponentsManager.register('header', function () {
    return {
        init: function (componentId, componentElement) {
            const menuMainMobile = $('.header__main--mobile');
            const menuHamburger = $('.header__hamburger');
            const hamburgerClose = $('.header__hamburgerClose');
            const searchOpen = $('.header__searchOpen');
            const searchControls = $('.header__searchControls');
            const searchControlInput = $('.header__searchControlInput');
            const searchForm = $('.header__searchForm');
            const headerLogo = $('.header__logoImg');
            const menu_list = $('.menu_list');
            const modalMenuHeader = document.querySelector('.header__main--mobile');
            const ScreenSize = rwd.ScreenSize;
            const stateVisibleClassName = 'state-visible';


            // Funkcja obsługująca rozwijanie/zwijanie hamburgera w wersji mobilnej
            function handleModalMenu() {
                trapFocusModal(modalMenuHeader);
                menuHamburger.onHeaderEvent('click', function () {
                    menuMainMobile.attr({ 'aria-hidden': false });
                    menuMainMobile.addClass(stateVisibleClassName);
                });
                hamburgerClose.onHeaderEvent('click', function () {
                    menuMainMobile.attr({ 'aria-hidden': true });
                    menuMainMobile.removeClass(stateVisibleClassName);
                });
            }

            // Rozwijanie pola wyszukiwania
            searchOpen.onHeaderEvent('click ', function (event) {
                if (searchOpen.hasClass("active")) {
                    searchOpen.parents().find(searchControls).addClass('active');
                    searchControlInput.focus();
                    searchOpen.removeClass("active");
                    event.stopPropagation();
                }
            });
            // Ukrywanie pola wyszukiwania po kliknięciu poza obszar inputa
            function hideSearchInput() {
                $(document).onPage("click keydown", function (event) {
                    var $target = $(event.target);
                    if (!$target.closest(searchControls).length && searchControls.is(":visible")) {
                        searchControls.removeClass('active');
                        componentElement.find('.header__searchOpen').addClass("active");
                    }
                });
            };

            // Wrzucenie do URLa wyników wyszukiwanie  wyszukiwanej frazy z inputa
            function searchResultOpen() {
                searchForm.on('submit', function (event) {
                    const searchText = searchControlInput.val().trim();
                    (searchText !== '') && (window.location.href = `/wyniki-wyszukiwania?q=${searchText}`);
                    event.preventDefault();
                });
            };

            menu_list.attr("role", "navigation");

            handleModalMenu();
            searchResultOpen();
            hideSearchInput();


            $("#outlet-content").onHeaderEvent('content-replace', function () {
                menuMainMobile.attr({ 'aria-hidden': true });
                menuMainMobile.removeClass(stateVisibleClassName);
            });

            $(window).onPage("cms-location-change", function () {
                headerLogo.focus();
            });

            $(document).onHeaderEvent(ScreenSize.SIZE_CHANGE_EVENT_NAME, function () {
                hideSearchInput();
            });
        }
    }
});
